<template>
  <div class="app-toolbar">
    <div class="filter">
      <app-form
        v-bind="$attrs"
        inline
        :model="model"
        :fields="fields"
        v-on="$listeners"
      />
    </div>
    <div class="btn-group">
      <el-button
        v-bind="$attrs"
        icon="el-icon-search"
        type="primary"
        @click="onSearch"
      >
        搜索
      </el-button>
      <el-button v-bind="$attrs" icon="el-icon-refresh" @click="onReset">
        重置
      </el-button>
      <el-button
        v-if="downloader"
        v-bind="$attrs"
        icon="el-icon-download"
        :loading="loading"
        @click="onDownload"
      >
        {{ downloader.text }}
      </el-button>
      <el-button
        v-if="creator"
        v-bind="$attrs"
        icon="el-icon-plus"
        @click="onCreating"
      >
        {{ creator.text }}
      </el-button>
      <slot name="button" v-bind="$attrs" />
    </div>
    <div class="append" v-if="$slots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
import { download } from '@/utils/request';

export default {
  name: 'AppToolbar',
  inheritAttrs: false,
  props: {
    model: Object,
    fields: Array,
    igrone: Array,
    downloader: Object,
    creator: Object
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    // 搜索
    onSearch() {
      const { model } = this;
      this.$emit('search', model);
    },
    // 重置
    onReset() {
      const { model, igrone } = this;
      for (const key in model) {
        // 排除
        if (!igrone?.includes(key)) {
          model[key] = undefined;
        }
        // 日期时间的键要设置空串，否则日期时间组件报错，element的BUG
        const isStartDate = key.toLowerCase().includes('startdate');
        const isEndDate = key.toLowerCase().includes('enddate');
        const isStartTime = key.toLowerCase().includes('starttime');
        const isEndTime = key.toLowerCase().includes('ebdtime');
        const isDateTime = isStartDate || isEndDate || isStartTime || isEndTime;
        if (isDateTime) {
          model[key] = '';
        }
      }
      this.$emit('reset', model);
    },
    // 导出
    async onDownload() {
      const { downloader, $message, model } = this;
      try {
        this.loading = true;
        const { url, method } = downloader;
        let params;
        let data;
        if (method == 'post') {
          data = model;
        } else {
          params = model;
        }
        await download({ url, params, data, method });
      } catch (error) {
        $message({ message: error, type: 'error' });
      } finally {
        this.$emit('download', model);
        this.loading = false;
      }
    },
    // 打开新增窗口
    onCreating() {
      this.$emit('creating');
    }
  }
};
</script>

<style lang="scss" scoped>
.app-toolbar {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;

  .filter {
    display: flex;
  }

  .btn-group {
    display: flex;
  }

  .append {
    display: flex;
    margin-top: 12px;
  }
}
</style>
