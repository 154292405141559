<template>
  <el-dialog v-bind="$attrs" :title="title" append-to-body v-on="$listeners">
    <el-form label-width="120px">
      <el-form-item label="发布总数">
        <el-radio-group v-model="total">
          <el-radio-button :label="10">10份</el-radio-button>
          <el-radio-button :label="20">20份</el-radio-button>
          <el-radio-button :label="30">30份</el-radio-button>
          <el-radio-button :label="40">40份</el-radio-button>
          <el-radio-button :label="50">50份</el-radio-button>
          <el-radio-button :label="60">60份</el-radio-button>
          <el-radio-button :label="100">100份</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="或输入数量">
        <el-input-number v-model="total" />
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item v-if="ifSelectable" label="每人最大可选择">
        <el-radio-group v-model="selectable">
          <el-radio-button :label="1">1份</el-radio-button>
          <el-radio-button :label="2">2份</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ifSelectable" label="或输入数量">
        <el-input-number v-model="selectable" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'AppNumberDialog',
  inheritAttrs: false,
  props: { selected: Object,ifSelectable: Boolean },
  data() {
    return {
      total: 30,
      selectable: 2
    };
  },
  computed: {
    title() {
      return this.selected?.name;
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel', false);
    },
    onConfirm() {
      const { total, selectable } = this;
      this.$emit('confirm', { total, selectable });
    }
  }
};
</script>

<style lang="scss" scoped></style>
