// 设置报修

export default [
  {
    path: '/device/repair',
    component: resolve =>
      require(['../components/page/device/repair'], resolve),
    meta: { title: '报修记录' }
  },
  {
    path: '/device/dispatch',
    component: resolve =>
      require(['../components/page/device/dispatch'], resolve),
    meta: { title: '任务派单记录' }
  }
];
