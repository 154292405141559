// 美团

export default [
  {
    path: '/meituan/applicationList',
    component: resolve =>
      require(['../components/page/meituan/applicationList'], resolve),
    meta: { title: '申请列表' }
  },
  {
    path: '/meituan/department',
    component: resolve =>
      require(['../components/page/meituan/department'], resolve),
    meta: { title: '分局管理' }
  },
  {
    path: '/meituan/statistics',
    component: resolve =>
      require(['../components/page/meituan/statistics'], resolve),
    meta: { title: '点餐统计' }
  },
  {
    path: '/meituan/detail',
    component: resolve =>
      require(['../components/page/meituan/detail'], resolve),
    meta: { title: '点餐明细' }
  },
  {
    path: '/meituan/invoiceHeader',
    component: resolve =>
      require(['../components/page/meituan/invoiceHeader'], resolve),
    meta: { title: '主体管理' }
  },
  {
    path: '/meituan/mail',
    component: resolve => require(['../components/page/meituan/mail'], resolve),
    meta: { title: '邮件配置' }
  },
  {
    path: '/meituan/consumptionStatistics',
    component: resolve =>
      require(['../components/page/meituan/consumptionStatistics'], resolve),
    meta: { title: '消费统计' }
  },

  {
    path: '/meituan/balancePush',
    component: resolve =>
      require(['../components/page/meituan/balancePush'], resolve),
    meta: { title: '余额推送' }
  },

  {
    path: '/meituan/statements',
    component: resolve =>
      require(['../components/page/meituan/statements'], resolve),
    meta: { title: '美团账单' }
  }
];
