// 管理员配置

export default [
  {
    path: '/admin/user',
    component: resolve => require(['../components/page/admin/user'], resolve),
    meta: { title: '用户管理' }
  },
  {
    path: '/admin/role',
    component: resolve => require(['../components/page/admin/role'], resolve),
    meta: { title: '角色管理' }
  },
  {
    path: '/admin/menu',
    component: resolve => require(['../components/page/admin/menu'], resolve),
    meta: { title: '菜单管理' }
  },
  {
    path: '/admin/department',
    component: resolve =>
      require(['../components/page/admin/department'], resolve),
    meta: { title: '部门管理' }
  },
  {
    path: '/admin/address',
    component: resolve =>
      require(['../components/page/admin/address'], resolve),
    meta: { title: '预约地址管理' }
  },
  {
    path: '/admin/dict',
    component: resolve => require(['../components/page/admin/dict'], resolve),
    meta: { title: '数据字典' }
  },
  {
    path: '/admin/article',
    component: resolve =>
      require(['../components/page/admin/article'], resolve),
    meta: { title: '文章管理' }
  },
  {
    path: '/admin/adminLog',
    component: resolve =>
        require(['../components/page/admin/AdminLog'], resolve),
    meta: { title: '登陆日志' }
  }
];
