var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-list"},[_vm._t("content",[_c('el-table',_vm._g(_vm._b({staticClass:"table",attrs:{"border":"","highlight-current-row":"","data":_vm.items}},'el-table',_vm.$attrs,false),_vm.$listeners),[[_vm._t("index",[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60","align":"center"}})]),_vm._l((_vm.fields),function(field,index){return _c('el-table-column',_vm._b({key:index,scopedSlots:_vm._u([(field.colors || field.color)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:({ color: _vm.getColor(row, field, field.colors) })},[_vm._v(" "+_vm._s(_vm.getValue(row, field))+" ")])]}}:(field.type == 'raw')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('pre',[_vm._v(_vm._s(row[field.prop]))])]}}:(field.type == 'switch')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',_vm._b({attrs:{"value":row[field.prop],"disabled":""}},'el-switch',field,false))]}}:(field.type == 'stats-menu')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('app-stats-menu',_vm._b({},'app-stats-menu',row,false))]}}:(field.type == 'menu')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.get(row, field.prop))+" "),(_vm.showClose(row, field))?_c('i',{staticClass:"el-icon-close",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clear-item', row, field)}}}):_vm._e()]),_c('div',{staticClass:"danger"},[(_vm.get(row, field.prop) !== '')?_c('span',[_vm._v("数量"+_vm._s(_vm.get(row, ((field.index) + ".totalNum"))))]):_vm._e()]),_c('div',{staticClass:"danger"},[(_vm.get(row, field.prop) !== '' && _vm.get(row, ((field.index) + ".subscribeNum")) > 0)?_c('span',[_vm._v("预约数量"+_vm._s(_vm.get(row, ((field.index) + ".subscribeNum"))))]):_vm._e()])]}}:(field.type == 'takeout')?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.get(row, field.prop))+" "),(_vm.showClose(row, field))?_c('i',{staticClass:"el-icon-close",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clear-item', row, field)}}}):_vm._e()]),(_vm.showOther(row, field))?_c('div',{staticClass:"other"},[_c('div',{staticClass:"danger"},[_c('span',[_vm._v("￥"+_vm._s(_vm.get(row, ((field.index) + ".price")))+", ")]),_c('span',[_vm._v("数量"+_vm._s(_vm.get(row, ((field.index) + ".totalNum"))))])]),_c('div',{staticClass:"warning"},[_c('span',[_vm._v(_vm._s(_vm.get(row, ((field.index) + ".description")))+", ")]),_c('span',[_vm._v("可选择"+_vm._s(_vm.get(row, ((field.index) + ".maxNum"))))])])]):_vm._e()]}}:null],null,true)},'el-table-column',field,false))}),(_vm.action)?_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._t("actions",null,{"row":row})]}}],null,true)},'el-table-column',_vm.customActionProps,false)):_vm._e()]],2)],{"items":_vm.items}),(_vm.pages)?_c('el-pagination',_vm._g(_vm._b({attrs:{"page-sizes":_vm.pageSizes,"background":"","layout":"sizes, prev, pager, next, jumper, ->, total, slot"},on:{"current-change":_vm.onCurrentChange,"size-change":_vm.onSizeChange}},'el-pagination',_vm.paging,false),_vm.$listeners)):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }