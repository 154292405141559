import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import { messages } from './components/common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import 'babel-polyfill';
import './assets/css/icon.css';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import './components/common/directives';
import './components/common2';
import publicMethods from './utils/publicMethods';
import VueCropper from 'vue-cropper'

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(VueCropper)

dayjs.locale('zh-cn');

Vue.prototype.$axios = axios;
Vue.prototype.$publicMethods = publicMethods;

const i18n = new VueI18n({
  locale: 'zh',
  messages
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
