<template>
  <el-table size="mini" border :highlight-current-row="false" :data="menuList">
    <el-table-column label="菜名" prop="name" />
    <el-table-column
      label="已预约(份)"
      prop="subscribeNum"
      :width="80"
      align="right"
    />
    <el-table-column
      label="已使用(份)"
      prop="usedNum"
      :width="80"
      align="right"
    />
    <el-table-column
      label="未使用(份)"
      prop="unusedNum"
      :width="80"
      align="right"
    />
  </el-table>
</template>

<script>
export default {
  name: 'AppStatsMenu',
  props: { menuList: Array }
};
</script>
