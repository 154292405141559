import Vue from 'vue';
import Router from 'vue-router';
import meituan from './meituan';
import beautiful from './beautiful';
import medical from './medical';
import appointment from './appointment';
import rending from './renting';
import sleep from './sleep';
import device from './device';
import equipment from './equipment';
import canteen from './canteen';
import issue from './issue';
import app from './app';
import admin from './admin';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/login',
      component: resolve => require(['../components/page/login'], resolve)
    },
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/',
      component: () => import('../components/common/Home'),
      meta: { title: '主框架' },
      children: [
        {
          path: '/dashboard',
          component() {
            return import('../components/page/dashboard');
          },
          meta: { title: '系统首页' }
        },
        // 美团
        ...meituan,
        // 理发、美容美发
        ...beautiful,
        // 医疗预约
        ...medical,
        // 洗衣预约、洗车预约、体检预约、心灵之家、医疗预约（旧版）、电影预约
        ...appointment,
        // 公用租房
        ...rending,
        // 住宿预约
        ...sleep,
        // 设备报修
        ...device,
        // 备装互换
        ...equipment,
        // 食堂管理
        ...canteen,
        // 反馈建议
        ...issue,
        // 小程序配置
        ...app,
        // 管理员
        ...admin
      ]
    },

    {
      path: '/404',
      component: resolve => require(['../components/page/404'], resolve),
      meta: {
        title: '404'
      }
    },
    {
      path: '/403',
      component: resolve => require(['../components/page/403'], resolve),
      meta: { title: '403' }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('userId');
  if (!token && to.path !== '/login') {
    next('/login');
  } else if (token && to.path == '/login') {
    next('/');
  } else {
    next();
  }
});

export default router;
