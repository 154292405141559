// 医疗预约

export default [
  {
    path: '/medical/list',
    component: resolve => require(['../components/page/medical/list'], resolve),
    meta: { title: '医疗预约' }
  },
  {
    path: '/medical/setting',
    component: resolve =>
      require(['../components/page/medical/setting'], resolve),
    meta: { title: '医疗预约设置' }
  },
  {
    path: '/medical/stats',
    component: resolve =>
      require(['../components/page/medical/stats'], resolve),
    meta: { title: '医疗预约统计' }
  }
];
