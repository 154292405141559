<template>
  <div class="app-view" :style="{ height: viewHeight }">
    <section
      v-for="(item, index) in items"
      :key="index"
      class="item"
      @click="$emit('select', item)"
    >
      <el-image class="img" :src="item.baseUrl + item.picPath" fit="cover" />
      <div class="name">{{ item.name }}</div>
      <div class="type" v-if="!showOther">
        <div>{{ typeList[item.type] }}</div>
        <div>{{ categoryList[item.category].label }}</div>
      </div>
      <div v-if="showOther || item.type == 4" style="display: flex;justify-content: space-between;">
        <div class="other">￥{{ item.price }}</div>
        <div class="other">{{ item.description }}</div>
      </div>

      <el-popconfirm
        v-if="!disableRemove"
        title="确定要删除这个菜单吗？"
        @confirm="$emit('remove', item)"
      >
        <template #reference>
          <el-button
            class="close"
            type="danger"
            size="small"
            circle
            icon="el-icon-delete"
            @click.stop
          />
        </template>
      </el-popconfirm>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppMenuView',
  inheritAttrs: false,
  props: {
    items: Array,
    showOther: Boolean,
    disableRemove: Boolean,
    viewHeight: String
  },
  data() {
    return {
      typeList: ['','早餐','中餐,晚餐','','外卖'],
      categoryList:[
        { label: '粉', value: 1 },
        { label: '面', value: 2 },
        { label: '粥', value: 3 },
        { label: '面点、蛋', value: 4 },
        { label: '饮品', value: 5 },
        { label: '套餐', value: 6 },
        { label: '饭', value: 7 }
      ],
    };
  }
};
</script>

<style lang="scss" scoped>
.app-view {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  overflow: auto;

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 140px;
    margin: 6px;
    border: thin solid #dcdfe6;
    border-radius: 3px;
    height: 177px;
    cursor: pointer;
    padding-bottom: 5px;

    &:hover {
      border: thin solid #409eff;

      .close {
        display: block;
      }
    }

    .close {
      display: none;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .img {
      width: 140px;
      height: 140px;
    }

    .name {
      margin: 6px;
      color: #303133;
      font-size: 18px;
    }

    .other {
      margin: 0 6px;
      color: #f56c6c;
      font-size: 14px;
    }

    .type {
      font-size: 14px;
      margin-left: 6px;
      display: flex;
      justify-content: space-between;
      margin-right: 6px;
    }
  }
}
</style>
