<template>
  <section class="app-form-setting">
    <div class="title">{{ title }}</div>
    <app-form v-bind="{ model, ...$attrs }" v-on="$listeners" />
  </section>
</template>

<script>
export default {
  name: 'AppFormSetting',
  inheritAttrs: false,
  props: { title: String, model: Object }
};
</script>

<style lang="scss" scoped>
.app-form-setting {
  display: flex;
  padding: 24px 60px;
  border-bottom: thin solid #ebeef5;

  .title {
    flex: 0 0 160px;
    font-weight: bold;
  }
}
</style>
