<template>
  <el-dialog
    v-bind="$attrs"
    width="600px"
    append-to-body
    center
    v-on="$listeners"
  >
    <template>
      <el-result icon="warning" title="请确认">
        <template slot="icon">
          <h1 :style="{ color: '#F56C6C', fontSize: '80px' }">
            {{ amount }}
          </h1>
        </template>
        <template slot="subTitle">
          <h3>是否完成了服务并收款？</h3>
        </template>
      </el-result>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            姓名
          </template>
          {{ model.appointmentUserName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            部门
          </template>
          {{ model.appointmentDepartmentName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            电话
          </template>
          {{ model.appointmentPhone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-coordinate"></i>
            警号
          </template>
          {{ model.appointmentPoliceCard }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            预约日期
          </template>
          {{ model.appointmentDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time"></i>
            预约时间
          </template>
          {{ time }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-thumb"></i>
            预约项目
          </template>
          {{ model.cosmetologyProjectName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-star-off"></i>
            是否会员价
          </template>
          {{ vip }}
        </el-descriptions-item>
        <el-descriptions-item :content-style="{ color: '#F56C6C' }">
          <template slot="label">
            <i class="el-icon-money"></i>
            单价
          </template>
          {{ amount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-edit-outline"></i>
            状态
          </template>
          <el-tag type="warning">已使用</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </template>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="onConfirm">
        我已收到款，并确认
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'AppResultDialog',
  inheritAttrs: false,
  props: {
    model: Object,
    loading: Boolean
  },
  computed: {
    vip() {
      const { model } = this;
      if (!model) {
        return '';
      }
      if (model.cosmetologyProjectSetmealBuyId) {
        return '是';
      } else {
        return '否';
      }
    },
    time() {
      const { model } = this;
      if (model && model.startTime && model.endTime) {
        return `${model.startTime}~${model.endTime}`;
      } else {
        return '';
      }
    },
    amount() {
      const { model } = this;
      return model.amount ? `￥${model.amount}` : '';
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', this.model);
    }
  }
};
</script>
