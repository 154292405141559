<template>
  <el-dialog
    v-bind="$attrs"
    append-to-body
    @open="onOpen"
    @close="onClose"
    v-on="$listeners"
  >
    <app-list
      v-loading="loading"
      ref="list"
      v-bind="$attrs"
      v-on="$listeners"
      @loading="loading = true"
      @loaded="loading = false"
    />
  </el-dialog>
</template>

<script>
export default {
  name: 'AppListDialog',
  inheritAttrs: false,
  data() {
    return {
      loading: false
    };
  },
  methods: {
    // 打开重新加载记录
    async onOpen() {
      const { $refs, $nextTick } = this;
      await $nextTick();
      await $refs.list.getItems();
    },
    // 关闭清空记录
    onClose() {
      const { $refs } = this;
      $refs.list.clear();
    }
  }
};
</script>
