import axios from 'axios';
import { Message } from 'element-ui';
import FileSaver from 'file-saver';
import router from '../router';
import Qs from 'qs';

const service = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  timeout: 30 * 1000
});

// 拦截器
service.interceptors.request.use(config => {
  // 令牌
  config.headers.common['token'] = sessionStorage.getItem('token');
  // GET请求数组的方式：'a=111&a=222&a=333'
  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: 'repeat'
    });
  };
  return config;
});

// 拦截器
service.interceptors.response.use(
  response => {
    // 原始响应
    if (response.config.raw) {
      return response;
    }
    // 响应错误
    if (response.status != 200) {
      Message.error(response.message);
      return Promise.reject(response);
    }

    // 响应体内的错误
    if (response.data.code != 200) {
      Message.error(response.data.msg);
      return Promise.reject(response.data);
    }

    // 正常响应
    return response.data;
  },
  error => {
    // 未认证，重定向到登陆页面
    if (error.response.status == 401) {
      sessionStorage.clear();
      return Promise.resolve(router.replace('/login'));
    }
    Message.error(error.message);
    return Promise.reject(error);
  }
);

export default service;

// 下载
export async function download(config) {
  const response = await service({
    responseType: 'blob',
    ...config,
    raw: true
  });

  // 提取文件名
  const fileName = response.headers['content-disposition'].match(
    /fileName=(.*)/i
  )[1];

  const blob = new Blob([response.data], {
    type: 'application/octet-stream'
  });

  FileSaver.saveAs(blob, decodeURIComponent(fileName));
}
