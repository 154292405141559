export default {
    // 获取当月某一天
    getCurrentMonthFirst(d) {
        var date = new Date();
        date.setDate(d);
        var month = parseInt(date.getMonth() + 1);
        var day = date.getDate();
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        return date.getFullYear() + '-' + month + '-' + day;

    },
    getHalfMonthDay() {
        var date = new Date();
        var day = date.getDate();
        var firstDate, lastDate
        if (day < 15) {
            firstDate = this.getCurrentMonthFirst(1) + " " + "00:00:00"
            lastDate = this.getCurrentMonthFirst(14) + " " + "23:59:59"
        } else {
            firstDate = this.getCurrentMonthFirst(15) + " " + "00:00:00"
            lastDate = this.getCurrentMonthLast() + " " + "23:59:59"
        }
        console.log('firstDate', firstDate);
        console.log('lastDate', lastDate);

        return [firstDate, lastDate]
    },

    getHalfMonth() {
        var date = new Date();
        var day = date.getDate();
        var firstDate, lastDate
        if (day < 15) {
            firstDate = this.getCurrentMonthFirst(1)
            lastDate = this.getCurrentMonthFirst(14)
        } else {
            firstDate = this.getCurrentMonthFirst(15)
            lastDate = this.getCurrentMonthLast()
        }

        return [firstDate, lastDate]
    },
    // 获取当月份最后一天
    getCurrentMonthLast() {
        var date = new Date();

        var currentMonth = date.getMonth();

        var nextMonth = ++currentMonth;

        var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);

        var oneDay = 1000 * 60 * 60 * 24;

        var lastTime = new Date(nextMonthFirstDay - oneDay);

        var month = parseInt(lastTime.getMonth() + 1);

        var day = lastTime.getDate();

        if (month < 10) {
            month = '0' + month

        }

        if (day < 10) {
            day = '0' + day

        }

        return date.getFullYear() + '-' + month + '-' + day;

    },


    formatDate(secs, type = 0) {
        //type是可选参数，因为json中时间的格式是year-month-date,不能识别XX年XX月XX日
        var t = new Date(secs);
        var year = t.getFullYear();
        var month = t.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        var date = t.getDate();
        if (date < 10) {
            date = "0" + date;
        }
        var hour = t.getHours();
        if (hour < 10) {
            hour = "0" + hour;
        }
        var minute = t.getMinutes();
        if (minute < 10) {
            minute = "0" + minute;
        }
        var second = t.getSeconds();
        if (second < 10) {
            second = "0" + second;
        }
        if (type == 0) {
            return year + "年" + month + "月" + date + "日";
        } else if (type == 2) {
            return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
        } else if (type == 5) {
            return year + "-" + month + "-" + date + "&&" + hour + ":" + minute + ":" + second;
        } else if (type == 3) {
            return hour + ":" + minute + ":" + second;
        } else if (type == 4) {
            return year + "-" + month + "-" + date
        } else {
            return year + "-" + month + "-" + date + " " + hour + "时";
        }
    },



};