<template>
  <el-dialog v-bind="$attrs" append-to-body @closed="onClosed" v-on="$listeners" @open="onOpen">
    <app-form
      ref="form"
      v-bind="$attrs"
      v-on="$listeners"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </el-dialog>
</template>

<script>
export default {
  name: 'AppFormDialog',
  inheritAttrs: false,
  methods: {
    async onOpen() {
      const { $nextTick, $refs } = this;
      await $nextTick();
      $refs.form.getOptions();
    },
    onConfirm() {
      this.$emit('update:visible', false);
    },
    onCancel() {
      this.$emit('update:visible', false);
    },
    onClosed(){
      this.$emit('onClosed');
    }
  }
};
</script>
