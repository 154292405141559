<template>
  <el-container class="app-page" v-loading="loading">
    <el-header height="auto">
      <slot name="header" v-bind="toolbar">
        <app-toolbar
          ref="toolbar"
          v-bind="toolbar"
          :creator="creator"
          v-on="$listeners"
          @change="onToolbarChange"
          @search="onSearch"
          @reset="onReset"
          @creating="onCreating"
        >
          <template #button="props">
            <slot name="button" v-bind="{ ...props }" />
          </template>
          <template #append>
            <slot name="append" />
          </template>
        </app-toolbar>
      </slot>
    </el-header>
    <el-main>
      <slot name="main" v-bind="list">
        <app-list
          ref="list"
          v-bind="list"
          :default-params="defaultParams"
          v-on="$listeners"
          @loading="onLoading"
          @loaded="onLoaded"
        >
          <template #index>
            <slot name="index" />
          </template>
          <template #actions="{ row ,field}">
            <slot name="actions" :row="row" :field="field" />
          </template>
          <template #content="{ items }">
            <slot name="content" :items="items" />
          </template>
        </app-list>
      </slot>
    </el-main>
    <app-form-dialog
      v-bind="dialog"
      :visible.sync="visible"
      @confirm="onConfirm"
      @onClosed="onClosed"
    />
    <slot name="dialog" />
  </el-container>
</template>

<script>
export default {
  name: 'AppPage',
  inheritAttrs: false,
  props: { toolbar: Object, list: Object, creator: Object, updater: Object },
  data() {
    return {
      loading: false,
      dialog: undefined,
      visible: false,
      params: undefined
    };
  },
  computed: {
    defaultParams() {
      return this.toolbar?.model;
    }
  },
  methods: {
    // 加载
    async getItems(model) {
      const { list } = this.$refs;
      await list.getItems(model);
    },
    // 工具栏变更
    onToolbarChange(e, field) {
      this.$emit('toolbar-change', e, field);
    },
    // 搜索
    async onSearch(model) {
      const { list } = this.$refs;
      list.reset();
      await this.getItems(model);
    },
    // 打开新增对话框
    onCreating() {
      this.dialog = this.creator;
      this.visible = true;
    },
    // 打开编辑对话框
    onUpdating() {
      this.dialog = this.updater;
      this.visible = true;
    },
    // 确认新增
    async onConfirm() {
      await this.getItems();
    },
    // 正在加载
    onLoading() {
      this.loading = true;
    },
    // 加载完成
    onLoaded() {
      this.loading = false;
    },
    onClosed(){
      this.getItems()
    },
    // 列表参数重置并重新加载
    async onReset() {
      const { list } = this.$refs;
      list.reset();
      await this.getItems();
    },
    // 工具栏参数重置
    reset() {
      const { toolbar } = this.$refs;
      toolbar.onReset();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-page {
  flex: 1 0;
  height: calc(100vh - 152px);
}

.el-container,
.el-header,
.el-main {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
}

.el-main {
  padding: 0;
  padding-top: 12px;
}
</style>
