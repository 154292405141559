// 美容美发

export default [
  {
    path: '/beautiful/haircut',
    component: resolve =>
      require(['../components/page/beautiful/haircut/list'], resolve),
    meta: { title: '理发预约' }
  },
  {
    path: '/beautiful/hairdressing/list',
    component: resolve =>
      require(['../components/page/beautiful/hairdressing/list'], resolve),
    meta: { title: '美发预约' }
  },
  {
    path: '/beautiful/hairdressing/buy',
    component: resolve =>
      require(['../components/page/beautiful/hairdressing/buy'], resolve),
    meta: { title: '会员购买' }
  },
  {
    path: '/beautiful/hairdressing/setting',
    component: resolve =>
      require(['../components/page/beautiful/hairdressing/setting'], resolve),
    meta: { title: '美发预约设置' }
  },
  {
    path: '/beautiful/beauty/list',
    component: resolve =>
      require(['../components/page/beautiful/beauty/list'], resolve),
    meta: { title: '美容预约' }
  },
  {
    path: '/beautiful/beauty/buy',
    component: resolve =>
      require(['../components/page/beautiful/beauty/buy'], resolve),
    meta: { title: '套餐购买' }
  },
  {
    path: '/beautiful/beauty/setting',
    component: resolve =>
      require(['../components/page/beautiful/beauty/setting'], resolve),
    meta: { title: '美容预约设置' }
  },
  {
    path: '/beautiful/stats/list',
    component: resolve =>
      require(['../components/page/beautiful/stats/list'], resolve),
    meta: { title: '美容美发统计' }
  }
];
