// 洗衣预约、洗车预约、体检预约、心灵之家、医疗服务、电影预约

export default [
  // 洗衣预约
  {
    path: '/appointment/washclothes',
    component: resolve =>
      require(['../components/page/washclothes/list'], resolve),
    meta: { title: '洗衣预约' }
  },
  // 洗车预约
  {
    path: '/appointment/washcar',
    component: resolve => require(['../components/page/washcar/list'], resolve),
    meta: { title: '洗车预约' }
  },
  // 体检预约
  {
    path: '/appointment/health/list',
    component: resolve => require(['../components/page/health/list'], resolve),
    meta: { title: '体检服务' }
  },
  {
    path: '/appointment/health/setmeal',
    component: resolve =>
      require(['../components/page/health/setmeal'], resolve),
    meta: { title: '套餐管理' }
  },
  {
    path: '/appointment/health/hospital',
    component: resolve =>
      require(['../components/page/health/hospital'], resolve),
    meta: { title: '医院管理' }
  },
  // 心灵之家
  {
    path: '/appointment/psychology/list',
    component: resolve =>
      require(['../components/page/psychology/list'], resolve),
    meta: { title: '心理咨询' }
  },
  {
    path: '/appointment/psychology/LeaderSet',
    component: resolve =>
      require(['../components/page/psychology/leaderSet'], resolve),
    meta: { title: '网上会诊' }
  },
  //  医疗服务
  {
    path: '/appointment/hospital/list',
    component: resolve =>
      require(['../components/page/hospital/list'], resolve),
    meta: { title: '医疗服务' }
  },
  {
    path: '/appointment/hospital/message',
    component: resolve =>
      require(['../components/page/hospital/message'], resolve),
    meta: { title: '网上会诊' }
  },
  //  电影预约
  {
    path: '/appointment/movie/main',
    component: resolve => require(['../components/page/movie/main'], resolve),
    meta: { title: '用户预约信息' }
  },
  {
    path: '/appointment/movie/info',
    component: resolve => require(['../components/page/movie/info'], resolve),
    meta: { title: '最新电影资讯' }
  },
  {
    path: '/appointment/movie/time',
    component: resolve => require(['../components/page/movie/time'], resolve),
    meta: { title: '电影场次设置' }
  }
];
