<template>
  <div class="app-list">
    <slot name="content" :items="items">
      <el-table
        class="table"
        v-bind="$attrs"
        border
        highlight-current-row
        :data="items"
        v-on="$listeners"
      >
        <template>
          <slot name="index">
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            />
          </slot>
          <el-table-column
            v-for="(field, index) in fields"
            v-bind="field"
            :key="index"
          >
            <template v-if="field.colors || field.color" #default="{ row }">
              <span :style="{ color: getColor(row, field, field.colors) }">
                {{ getValue(row, field) }}
              </span>
            </template>
            <template v-else-if="field.type == 'raw'" #default="{ row }">
              <pre>{{ row[field.prop] }}</pre>
            </template>
            <template v-else-if="field.type == 'switch'" #default="{ row }">
              <el-switch :value="row[field.prop]" v-bind="field" disabled />
            </template>
            <template v-else-if="field.type == 'stats-menu'" #default="{ row }">
              <app-stats-menu v-bind="row" />
            </template>
            <template v-else-if="field.type == 'menu'" #default="{ row }">
              <div>
                {{ get(row, field.prop) }}
                <i
                  v-if="showClose(row, field)"
                  class="el-icon-close"
                  @click.stop="$emit('clear-item', row, field)"
                />
              </div>
              <div class="danger">
                <span v-if="get(row, field.prop) !== ''">数量{{ get(row, `${field.index}.totalNum`) }}</span>
              </div>
              <div class="danger">
                <span v-if="get(row, field.prop) !== '' && get(row, `${field.index}.subscribeNum`) > 0">预约数量{{ get(row, `${field.index}.subscribeNum`) }}</span>
              </div>
            </template>
            <template v-else-if="field.type == 'takeout'" #default="{ row }">
              <div>
                {{ get(row, field.prop) }}
                <i
                  v-if="showClose(row, field)"
                  class="el-icon-close"
                  @click.stop="$emit('clear-item', row, field)"
                />
              </div>
              <div v-if="showOther(row, field)" class="other">
                <div class="danger">
                  <span>￥{{ get(row, `${field.index}.price`) }}, </span>
                  <span>数量{{ get(row, `${field.index}.totalNum`) }}</span>
                </div>
                <div class="warning">
                  <span>{{ get(row, `${field.index}.description`) }}, </span>
                  <span>可选择{{ get(row, `${field.index}.maxNum`) }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="action" v-bind="customActionProps">
            <template #default="{ row }">
              <slot name="actions" :row="row" />
            </template>
          </el-table-column>
        </template>
      </el-table>
    </slot>
    <el-pagination
      v-if="pages"
      v-bind="paging"
      :page-sizes="pageSizes"
      background
      layout="sizes, prev, pager, next, jumper, ->, total, slot"
      v-on="$listeners"
      @current-change="onCurrentChange"
      @size-change="onSizeChange"
    />
  </div>
</template>

<script>
import request from '@/utils/request';
import get from 'lodash/get';

export default {
  name: 'AppList',
  inheritAttrs: false,
  props: {
    // 请求api时返回的数据结构，有三种模式，new, all
    mode: String,
    // 默认参数
    defaultParams: Object,
    // 查询参数
    params: Object,
    // 查询地址
    url: String,
    // 请求方法
    method: { type: String, default: 'get' },
    // 表格字段
    fields: Array,
    // 是否显示操作按钮
    action: { type: Boolean, default: true },
    // 默认页项数量
    defaultPageSize: { type: Number, default: 20 },
    // 是否显示分页
    pages: { type: Boolean, default: true },
    // 页最大数量选项
    pageSizes: {
      type: Array,
      default() {
        return [20, 50, 100, 500];
      }
    },
    // 自定义请求
    customRequest: Function,
    // 操作列定义
    customAction: Object
  },
  data() {
    return {
      // 结果集
      items: [],
      // 分页参数
      paging: {
        currentPage: 1,
        pageSize: this.defaultPageSize,
        pageCount: 0,
        total: 0
      },
      get
    };
  },
  computed: {
    // 自定义操作列声明
    customActionProps() {
      return Object.assign(
        {
          label: '操作',
          width: '120',
          headerAlign: 'center',
          align: 'center'
        },
        this.customAction
      );
    }
  },
  watch: {
    items: {
      handler(newValue) {
        this.$emit('data-change', newValue);
      },
      deep: true
    }
  },
  async created() {
    await this.getItems();
  },
  methods: {
    // 两种api查询后返回不同的结构
    // 老结构
    async getItemsOld(model) {
      const { url, method, defaultParams, params = {}, paging } = this;
      // 分页参数和查询参数合并
      const data = Object.assign(
        {
          startPage: paging.currentPage,
          pageSize: paging.pageSize,
          pages: paging.pageCount,
          total: paging.total
        },
        defaultParams,
        params,
        model
      );
      // 请求配置
      let config;
      if (method == 'get') {
        config = { url, method, params: data };
      } else {
        config = { url, method, data };
      }
      // 请求
      const result = await request(config);
      const { records, current, pages, size, total } = result.data;
      // 列表数据
      this.items = records;
      // 分页数据
      this.paging = {
        currentPage: current,
        pageCount: pages,
        pageSize: size,
        total
      };
      return result;
    },
    // 新结构
    async getItemsNew(model) {
      const { url, method, defaultParams, params = {}, paging } = this;
      // 分页参数和查询参数合并
      const data = Object.assign({ ...paging }, defaultParams, params, model);
      // 请求配置
      let config;
      if (method == 'get') {
        config = { url, method, params: data };
      } else {
        config = { url, method, data };
      }
      // 请求
      const result = await request(config);
      const {
        currentPage,
        pageCount,
        pageSize,
        total,
        data: items
      } = result.data;
      // 列表数据
      this.items = items;
      // 分页数据
      this.paging = {
        currentPage,
        pageSize,
        pageCount,
        total
      };
      return result;
    },
    async getItemsAll(model) {
      const { url, method, defaultParams, params = {} } = this;
      // 请求配置
      let config;
      if (method == 'get') {
        config = {
          url,
          method,
          params: Object.assign(defaultParams, params, model)
        };
      } else {
        config = {
          url,
          method,
          data: Object.assign(defaultParams, params, model)
        };
      }
      // debugger;
      // 请求
      const result = await request(config);
      // 列表数据
      this.items = result.data;
      return result;
    },
    // 获得列表项
    async getItems(model) {
      let result;
      const {
        customRequest,
        mode,
        getItemsOld,
        getItemsNew,
        getItemsAll
      } = this;
      try {
        // 正在加载
        this.$emit('loading');
        const actions = { new: getItemsNew, all: getItemsAll };
        const action = actions[mode];
        if (customRequest) {
          result = await customRequest(this, model);
        } else if (action) {
          result = await action(model);
        } else {
          result = await getItemsOld(model);
        }
      } finally {
        // 加载完成
        this.$emit('loaded', result);
      }
    },
    // 页变更
    async onCurrentChange(e) {
      const { paging, getItems } = this;
      paging.currentPage = e;
      await getItems();
    },
    // 页大小变更
    async onSizeChange(e) {
      const { paging, getItems } = this;
      paging.pageSize = e;
      await getItems();
    },
    // 重置分页参数
    reset() {
      this.paging = {
        currentPage: 1,
        pageSize: this.defaultPageSize,
        pageCount: 0,
        total: 0
      };
    },
    // 重置分页参数并清空数据
    clear() {
      this.reset();
      this.items = [];
    },
    // 获得颜色
    getColor(row, field, emun) {
      const colors = {
        primary: '#409EFF',
        success: '#67C23A',
        warning: '#E6A23C',
        danger: '#F56C6C',
        info: '#909399'
      };
      if (emun) {
        const key = field.colors[row[field.prop]];
        return colors[key];
      } else {
        const key = field.color;
        return colors[key];
      }
    },
    // 获得值
    getValue(row, field) {
      const value = row[field.prop];
      return field.formatter ? field.formatter(row) : value;
    },
    // 是否显示清空按钮，名称不为空，不上架的可以清空
    showClose(row, field) {
      const cell = row[field.index];
      return cell.name != '' && cell.isOut == 0;
    },
    // 是否显示其他信息，外卖使用
    showOther(row, field) {
      const cell = row[field.index];
      return field.showOther && cell.name != '';
    }
  }
};
</script>

<style lang="scss" scoped>
.app-list {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  overflow: auto;

  .table {
    display: flex;
    flex-direction: column;

    ::v-deep .el-table__body-wrapper {
      flex: 1 0;
      padding-bottom: 6px;
      overflow: auto;
    }

    ::v-deep td {
      padding: 4px 0;
      font-size: 20px;
    }

    ::v-deep .el-button {
      padding: 4px;
      font-size: 16px;
    }

    ::v-deep .el-switch__core {
      width: 40px !important;
    }

    .other {
      font-size: 16px;

      .danger {
        color: #f56c6c;
      }

      .warning {
        color: #e6a23c;
      }
    }
  }
}
</style>
