// 备装互换

export default [
  {
    path: '/equipment/exchange',
    component: resolve =>
      require(['../components/page/equipment/exchange'], resolve),
    meta: { title: '互换信息' }
  },
  {
    path: '/equipment/size',
    component: resolve =>
      require(['../components/page/equipment/size'], resolve),
    meta: { title: '尺码配置' }
  },
  {
    path: '/equipment/type',
    component: resolve =>
      require(['../components/page/equipment/type'], resolve),
    meta: { title: '类型配置' }
  }
];
