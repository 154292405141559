// 住宿预约

export default [
  {
    path: '/sleep/list',
    component: resolve => require(['../components/page/sleep/list'], resolve),
    meta: { title: '用户住宿预约' }
  },
  {
    path: '/sleep/room',
    component: resolve => require(['../components/page/sleep/room'], resolve),
    meta: { title: '备勤室管理' }
  }
];
