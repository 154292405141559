// 租房

export default [
  {
    path: '/renting/room',
    component: resolve => require(['../components/page/renting/room'], resolve),
    meta: { title: '房源管理' }
  },
  {
    path: '/renting/list',
    component: resolve => require(['../components/page/renting/list'], resolve),
    meta: { title: '用户租房信息' }
  }
];
