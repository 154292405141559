// 小程序配置

export default [
  {
    path: '/app/user',
    component: resolve => require(['../components/page/app/user'], resolve),
    meta: { title: '用户配置' }
  },
  {
    path: '/app/whiteList',
    component: resolve =>
      require(['../components/page/app/whiteList'], resolve),
    meta: { title: '白名单用户' }
  },
  {
    path: '/app/role',
    component: resolve => require(['../components/page/app/role'], resolve),
    meta: { title: '角色配置' }
  },
  {
    path: '/app/menu',
    component: resolve => require(['../components/page/app/menu'], resolve),
    meta: { title: '菜单配置' }
  },
  {
    path: '/app/log',
    component: resolve => require(['../components/page/app/Log'], resolve),
    meta: { title: '登陆日志' }
  }
];
