<template>
  <el-tabs v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-tabs>
</template>

<script>
export default {
  name: 'AppTabs',
  inheritAttrs: true
};
</script>

<style lang="scss">
.el-tab-pane {
  height: 100%;
}
</style>

<style lang="scss" scoped>
.el-tabs {
  height: 100%;

  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
  }

  ::v-deep .el-alert__title {
    font-size: 1.5em;
    line-height: inherit;
  }
}
</style>
